import { storyblokEditable } from '@storyblok/react'
import { motion, useScroll, useTransform } from 'framer-motion'
import NextImage from 'next/image'
import { useRef, useState } from 'react'

import { StoryblokCallToAction } from 'components/blocks/StoryblokCallToAction'
import { StoryblokImage } from 'components/blocks/StoryblokImage'
import { Icon } from 'components/ui/Icon'
import VideoOverlay from 'components/ui/VideoOverlay'
import { ClinicHeroStoryblok } from 'lib/storyblok/types'
import {
  getAnchorFromCmsLink,
  getStoryblokImageAttributes,
  textByLine,
} from 'lib/utils/content'
import { isSourceType } from 'lib/utils/isSourceType'

type ClinicHeroProps = {
  block: ClinicHeroStoryblok
}

export const ClinicHero = ({ block }: ClinicHeroProps) => {
  const {
    headline,
    address_title,
    address,
    see_map,
    open_hours_title,
    open_hours,
    ctas,
    images_layout,
    images,
    media,
  } = block

  const [videoOverlay, setVideoOverlay] = useState<string | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start start', 'end start'],
  })

  const topTranslateY = useTransform(scrollYProgress, [0, 1], [19, -100])
  const bottomTranslateY = useTransform(scrollYProgress, [0, 1], [-25, 100])

  const mobileTranslateX = useTransform(scrollYProgress, [0, 1], [0, -110])

  const horizontalMediaClassNames = [
    'rounded-tr-5 rounded-bl-5 relative overflow-hidden col-span-1',
    'relative overflow-hidden col-span-1 row-span-2 rounded-tl-5 rounded-br-5',
    'rounded-tr-5 rounded-bl-5 relative overflow-hidden col-span-2 row-span-2',
  ]

  return (
    <section
      ref={containerRef}
      className="w-full max-w-screen-desktop overflow-hidden mx-auto"
      {...storyblokEditable(block)}
    >
      {videoOverlay && (
        <VideoOverlay
          videoUrl={videoOverlay}
          setVideoOverlay={setVideoOverlay}
        />
      )}

      <div className="relative flex flex-col items-start w-full py-8 px-4 z-10 md:p-8 lg:flex-row lg:justify-between lg:gap-16 lg:py-0 lg:pb-15 lg:px-20 lg:max-h-[636px]">
        <div className="flex flex-col lg:pt-15 lg:max-w-[621px]">
          {headline && (
            <h1 className="pb-10 text-title-card lg:text-display font-regular">
              {headline}
            </h1>
          )}

          <div className="flex flex-col gap-10">
            <div className="flex flex-col gap-5 max-w-[355px]">
              <div className="space-y-1">
                {address_title && (
                  <p className="opacity-60 text-eighteen font-regular">
                    {address_title}
                  </p>
                )}

                {address && (
                  <>
                    {textByLine(address, (part) => (
                      <p className="text-eighteen">{part}</p>
                    ))}
                  </>
                )}
              </div>

              {see_map &&
                see_map[0]?.link &&
                (() => {
                  const { href, target, rel } = getAnchorFromCmsLink(
                    see_map[0].link
                  )

                  return (
                    <a
                      href={href}
                      target={target}
                      rel={rel}
                      className="underline text-foreground w-fit"
                    >
                      {see_map[0].label}
                    </a>
                  )
                })()}

              <div className="space-y-1">
                {open_hours_title && (
                  <p className=" opacity-60 text-eighteen font-regular">
                    {open_hours_title}
                  </p>
                )}

                {open_hours && (
                  <>
                    {textByLine(open_hours, (part) => (
                      <p className="text-eighteen">{part}</p>
                    ))}
                  </>
                )}
              </div>
            </div>

            {ctas?.length && (
              <div className="flex gap-3">
                {ctas.map((cta) => (
                  <StoryblokCallToAction key={cta._uid} cta={cta} />
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Mobile elements */}
        <motion.div
          className="relative flex gap-5 pt-5 lg:hidden"
          style={{ x: mobileTranslateX }}
        >
          {images &&
            (!media || media?.length === 0) &&
            images.slice(0, 2).map((image, i) => (
              <div key={i} className="flex gap-5">
                {i === 1 && (
                  <span className="w-[202px] h-[205px] rounded-5 bg-[linear-gradient(128deg,hsl(var(--twc-background-secondary-lgbtqia)/1)_-51.44%,hsl(var(--twc-background-secondary-donation)/1)_100%)]" />
                )}
                <div className="relative w-[290px] h-[205px] rounded-tr-12 rounded-bl-12 overflow-hidden flex-shrink-0">
                  <NextImage
                    fill
                    priority
                    src={getStoryblokImageAttributes(image).src}
                    alt={getStoryblokImageAttributes(image).alt}
                    className="pointer-events-none object-cover h-full"
                  />
                </div>
              </div>
            ))}

          {media &&
            media.length > 0 &&
            media.slice(0, 2).map((asset, i) => (
              <div key={i} className="flex gap-5">
                {i === 1 && (
                  <span className="w-[202px] h-[205px] rounded-5 bg-[linear-gradient(128deg,hsl(var(--twc-background-secondary-lgbtqia)/1)_-51.44%,hsl(var(--twc-background-secondary-donation)/1)_100%)]" />
                )}
                {asset.media.filename &&
                isSourceType(asset.media.filename, 'image') ? (
                  <div className="relative w-[290px] h-[205px] rounded-tr-12 rounded-bl-12 overflow-hidden flex-shrink-0">
                    <StoryblokImage
                      asset={asset.media}
                      className="h-full w-full object-cover"
                    />
                  </div>
                ) : (
                  <div className="group relative h-full w-full">
                    <video
                      muted
                      playsInline
                      loop
                      autoPlay
                      disablePictureInPicture
                      className="h-full w-full object-cover"
                    >
                      <source src={asset.media.filename} />
                    </video>
                    <button
                      onClick={() =>
                        asset.video_youtube_link
                          ? setVideoOverlay(asset.video_youtube_link)
                          : setVideoOverlay(asset.media.filename)
                      }
                      className="absolute right-2 bottom-2 rounded-full bg-white/80 opacity-100 md:opacity-0 md:transition-opacity md:hover:bg-white md:group-hover:opacity-100 flex items-center justify-center p-2"
                      aria-label="Open video"
                    >
                      <Icon icon="expand" size={16} />
                    </button>
                  </div>
                )}
              </div>
            ))}
        </motion.div>

        {/* Desktop elements */}
        {images_layout === 'vertical' && (
          <div className="relative gap-5 hidden lg:flex">
            <motion.div
              className="flex flex-col gap-5"
              style={{ y: topTranslateY }}
            >
              {images && images.length > 2 && (!media || media?.length === 0)
                ? images?.slice(0, 2).map((image, i) => {
                    const { alt, src } = getStoryblokImageAttributes(image)
                    return (
                      <div
                        key={i}
                        className="relative w-[305px] h-[420px] rounded-tr-[48px] rounded-bl-[48px] overflow-hidden flex-shrink-0"
                      >
                        <NextImage
                          fill
                          priority
                          src={src}
                          alt={alt}
                          sizes={i === 0 ? undefined : '300'}
                          className="pointer-events-none object-cover h-full"
                        />
                      </div>
                    )
                  })
                : media &&
                  media.length > 0 &&
                  media?.slice(0, 2).map((asset, i) => (
                    <div
                      key={i}
                      className="relative w-[305px] h-[420px] rounded-tr-[48px] rounded-bl-[48px] overflow-hidden flex-shrink-0"
                    >
                      <StoryblokImage
                        asset={asset.media}
                        className="h-full w-full object-cover"
                      />
                    </div>
                  ))}
            </motion.div>

            <motion.div
              className="flex flex-col gap-5"
              style={{ y: bottomTranslateY }}
            >
              {/* Gradient */}
              <span className="w-[305px] h-[220px] rounded-5 bg-[linear-gradient(128deg,hsl(var(--twc-background-secondary-lgbtqia)/1)_-51.44%,hsl(var(--twc-background-secondary-donation)/1)_100%)]" />

              {images?.[2] && (!media || media?.length === 0)
                ? (() => {
                    const { alt, src } = getStoryblokImageAttributes(images[2])
                    return (
                      <div className="relative w-[305px] h-[420px] rounded-tr-[48px] rounded-bl-[48px] overflow-hidden flex-shrink-0">
                        <NextImage
                          fill
                          priority
                          sizes="300"
                          src={src}
                          alt={alt}
                          className="pointer-events-none object-cover h-full"
                        />
                      </div>
                    )
                  })()
                : media?.[2] && (
                    <div className="relative w-[305px] h-[420px] rounded-tr-[48px] rounded-bl-[48px] overflow-hidden flex-shrink-0">
                      <StoryblokImage
                        asset={media[2].media}
                        className="h-full w-full object-cover"
                      />
                    </div>
                  )}
            </motion.div>
          </div>
        )}

        {images_layout === 'horizontal' && (
          <div
            className={`
            hidden lg:grid
            grid-cols-[0.32fr_0.32fr_0.36fr]
            grid-rows-[0.47fr_0.23fr_0.3fr]
            gap-5 
            w-[670px] 
            h-[463px]
            self-end
          `}
          >
            <div className="rounded-5 bg-[linear-gradient(128deg,_hsl(var(--twc-background-secondary-advice)),_hsl(var(--twc-background-secondary-preservation)))]" />

            {images &&
              (!media || media?.length === 0) &&
              images.map((image, i) => (
                <div key={i} className={horizontalMediaClassNames[i]}>
                  <NextImage
                    fill
                    sizes="200"
                    alt={getStoryblokImageAttributes(image)?.alt}
                    src={getStoryblokImageAttributes(image)?.src}
                    className="pointer-events-none flex-shrink-0 object-cover"
                  />
                </div>
              ))}

            {media &&
              media?.length > 0 &&
              media.map((asset, i) => (
                <div key={i} className={horizontalMediaClassNames[i]}>
                  {isSourceType(asset.media.filename, 'image') ? (
                    <StoryblokImage
                      asset={asset.media}
                      className="object-cover h-full w-full"
                    />
                  ) : (
                    <div className="group relative h-full w-full">
                      <video
                        muted
                        playsInline
                        loop
                        autoPlay
                        disablePictureInPicture
                        className="h-full w-full object-cover"
                      >
                        <source src={asset.media.filename} />
                      </video>
                      <button
                        onClick={() =>
                          asset.video_youtube_link
                            ? setVideoOverlay(asset.video_youtube_link)
                            : setVideoOverlay(asset.media.filename)
                        }
                        className="absolute right-2 bottom-2 rounded-full bg-white/80 opacity-100 md:opacity-0 md:transition-opacity md:hover:bg-white md:group-hover:opacity-100 flex items-center justify-center p-2"
                        aria-label="Open video"
                      >
                        <Icon icon="expand" size={16} />
                      </button>
                    </div>
                  )}
                </div>
              ))}

            <div className="rounded-5 bg-[linear-gradient(128deg,#f0e7b2,#ebd3c5)]" />
          </div>
        )}
      </div>
    </section>
  )
}
